import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './ConverterContainer.scss';
import UploadComponent from './UploadComponent';
import AdSenseUnit from './AdSense'
import AdSenseUnitH from './AdSenseH'
import AdSenseUnitHUC from './AdSenseHUC'
import Description from './Description';
import logo from './logo.png';

interface MenuItemProps {
    createClickHandler: (activeItemParam: any) => (e: React.MouseEvent<HTMLDivElement>) => void;
    item: { text: string,from: string, to: string };
    style: string;
}

const menuItemsOptions = [
    {text: 'JPG to PNG', from: 'jpeg', to: 'png', path: 'jpeg-to-png' },
    {text: 'PNG to JPG', from: 'png', to: 'jpeg', path: 'png-to-jpeg' },
    {text: 'HEIC to JPG', from: 'heic', to: 'jpeg', path: 'heic-to-jpeg' },
    {text: 'Webp to JPG', from: 'webp', to: 'jpeg', path: 'webp-to-jpeg' },
    {text: 'JPG to HEIC', from: 'jpeg', to: 'heic', path: 'jpeg-to-heic' },
    {text: 'JPG to Webp', from: 'jpeg', to: 'webp', path: 'jpeg-to-webp' },
  ]
  
function ConverterContainer(props: any) {
const navigate = useNavigate();
const selectedItem = menuItemsOptions.find(item => item.text === props.preselected) || menuItemsOptions[0];

// ensures the UploadComponent is generated freshly without any previous state
const [key, setKey] = useState(0);
const [activeItem, setActiveItem] = useState(selectedItem)


const createClickHandler = (activeItemP: any) => (e:any) => {
    e.preventDefault()
    setActiveItem(activeItemP)

    navigate('/'+activeItemP.path);
    setKey(key + 1);
}

    const menuItems = menuItemsOptions.map(item => <MenuItem style = {activeItem.text === item.text ? 'selected' : 'menu-item'} item={ item } createClickHandler={ createClickHandler }/>)
                                                
    return (
        <div>
            <a href='/'><img className='logo' alt='logo' src={logo}></img></a>
        <div className="title-ad">
            <h3 className='title'>Our tool converts your image from {activeItem.text} format by applying proper conversion algorithms. We belive in fast and reliable service, so our website does not ask for email or credit card information.
                It's free and going to stay free forever, without any limit in file size or the number of files.</h3>
            {/* <AdSenseUnit/> */}
        </div>
        <ul className='header_list'>
            <li className='header__list-item'><p className='header_text'>Drag and drop your IMAGES to the section below or select IMAGES by clicking "Choose your file" button.</p></li>
            <li className='header__list-item'><p className='header_text'>Click CONVERT and depending on your network speed you will get your images in seconds.</p></li>
            <li className='header__list-item'><p className='header_text'>You can download the converted images one-by-one or click DOWNLOAD ALL to get your images in a zip file.</p></li>
        </ul>
        <div className='container'>
            <AdSenseUnitH/>
            <div className='menu-container'>
                { menuItems }
            </div>
            { activeItem.text ?
                <div className='upload-container'>
                    <UploadComponent key={key} from={activeItem.from} to={activeItem.to} />
                </div>
            :null}
            {/* <AdSenseUnitHUC/> */}
            <Description useCase={activeItem.text}/>
        </div>
    </div>
    )
}
  
  ///////////////////
  // MenuItem      //
  ///////////////////
function MenuItem({ createClickHandler, item, style }: MenuItemProps) {
    const clickHandler = createClickHandler(item)

    return (
        <div 
        className={style}
        id={ item.text }
        onClick={ clickHandler }
        >
        { item.text }
        </div>
    )
}

export default ConverterContainer;