import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ConverterContainer from './modules/ConverterContainer';

const menuItemsOptions = [
  {text: 'JPG to PNG', from: 'jpeg', to: 'png', path: 'jpeg-to-png' },
  {text: 'PNG to JPG', from: 'png', to: 'jpeg', path: 'png-to-jpeg' },
  {text: 'HEIC to JPG', from: 'heic', to: 'jpeg', path: 'heic-to-jpeg' },
  {text: 'Webp to JPG', from: 'webp', to: 'jpeg', path: 'webp-to-jpeg' },
  {text: 'JPG to HEIC', from: 'jpeg', to: 'heic', path: 'jpeg-to-heic' },
  {text: 'JPG to Webp', from: 'jpeg', to: 'webp', path: 'jpeg-to-webp' },
]

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
            <Route path={"/"} element={<ConverterContainer preselected={menuItemsOptions[0].text}/>} />
            <Route path={menuItemsOptions[0].path} element={<ConverterContainer preselected={menuItemsOptions[0].text}/>} />
            <Route path={menuItemsOptions[1].path} element={<ConverterContainer preselected={menuItemsOptions[1].text}/>} />
            <Route path={menuItemsOptions[2].path} element={<ConverterContainer preselected={menuItemsOptions[2].text}/>} />
            <Route path={menuItemsOptions[3].path} element={<ConverterContainer preselected={menuItemsOptions[3].text}/>} />
            <Route path={menuItemsOptions[4].path} element={<ConverterContainer preselected={menuItemsOptions[4].text}/>} />
            <Route path={menuItemsOptions[5].path} element={<ConverterContainer preselected={menuItemsOptions[5].text}/>} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
