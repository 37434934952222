import React from 'react';
import './Description.css';
import TermsPrivacyPopup from './TermsAndPrivacy';

const summaryContent = [
    {useCase: 'JPG to PNG', summary1: [
        'Converting JPEG images to PNG format entails a technical process aimed at maintaining image quality and transparency. While JPEG utilizes lossy compression to reduce file size by discarding some image data, PNG employs lossless compression, preserving all details. During conversion, the JPEG file undergoes decoding to reconstruct the image, potentially resulting in a slight loss of quality due to the initial compression. However, PNG conversion ensures no further loss occurs, retaining image fidelity and any transparency elements present.',
        'Key considerations during conversion include color space management and transparency preservation. JPEG typically employs the YCbCr color space, which may require conversion to the RGB color space used in PNG. Additionally, PNG supports alpha channels for transparency, necessitating accurate preservation or simulation of transparency during conversion. Techniques such as background removal and alpha channel creation may be employed to maintain the intended visual appearance of the image.',
        'Moreover, metadata preservation and file optimization play vital roles in the conversion process. Retaining or appropriately modifying metadata, such as EXIF data, ensures the integrity and context of the image are preserved. File optimization techniques, such as reducing unnecessary metadata and applying PNG-specific compression algorithms, are implemented to minimize file size without compromising image quality. In essence, the technical conversion of JPEG to PNG involves meticulous attention to detail to ensure optimal output while preserving image fidelity and transparency.',
    ], summary2: [
        'Converting JPG to PNG offers several advantages, making it a preferred choice in certain scenarios. Firstly, PNG employs lossless compression, retaining all original image details without sacrificing quality. This is particularly beneficial for images with text, graphics, or sharp edges, as PNG preserves clarity and prevents artifacts commonly associated with JPEG compression.',
        'Secondly, PNG supports transparency, allowing for the creation of images with smooth edges that seamlessly blend into different backgrounds. This feature is invaluable for web graphics, logos, and illustrations where maintaining background transparency is crucial for integration into various design layouts.',
        'Furthermore, PNG is well-suited for images requiring multiple edits or overlays due to its lossless nature. Each time a PNG image is saved, it maintains its original quality, making it ideal for projects where preservation of image integrity across multiple iterations is essential. Overall, converting JPG to PNG ensures higher image quality, transparency support, and preservation of visual fidelity, making it a preferred format for certain applications.'
    ]},
    {useCase: 'PNG to JPG', summary1: [
        'Converting PNG (Portable Network Graphics) images to JPG (Joint Photographic Experts Group) format involves a technical process that alters the underlying data structure and compression methods. PNG, known for its lossless compression, maintains image quality and supports transparency, making it ideal for graphics with sharp edges or transparent backgrounds. However, JPG utilizes lossy compression, sacrificing some image quality to reduce file size. During conversion, the PNG data undergoes a transformation where unnecessary details are discarded to achieve JPGs smaller file size, resulting in a loss of quality, particularly in areas with fine details or sharp transitions.',
        'One crucial aspect of PNG to JPG conversion is color space management. PNG typically uses the RGB color space, while JPG often employs the YCbCr color space. During conversion, color space transformation may occur, affecting the color representation in the final JPG image. Additionally, since JPG does not support transparency, any transparent areas in the PNG image must be filled with a background color or removed altogether during the conversion process to ensure compatibility with the JPG format.',
        'Furthermore, optimization techniques play a significant role in PNG to JPG conversion to strike a balance between file size and image quality. Various algorithms are employed to compress the image data efficiently while minimizing perceptible loss in quality. However, its essential to consider the trade-off between file size reduction and maintaining acceptable image fidelity, especially in applications where image quality is paramount. Overall, PNG to JPG conversion involves technical considerations such as color space transformation, transparency handling, and optimization to achieve a suitable balance between file size and image quality.'
    ], summary2: [
        'Converting PNG (Portable Network Graphics) images to JPG (Joint Photographic Experts Group) format is often preferred for several practical reasons. Firstly, JPG employs lossy compression, resulting in smaller file sizes compared to PNGs lossless compression. This reduction in file size makes JPG more suitable for web use, where faster loading times and reduced bandwidth consumption are crucial factors. Moreover, the smaller file size of JPG images allows for more efficient storage and sharing, particularly when dealing with large quantities of images.',
        'Secondly, JPG format is widely supported across various platforms, software, and devices, making it highly versatile for sharing and displaying images. Whether its uploading photos to social media platforms, sending images via email, or embedding them in documents, JPG ensures compatibility and ease of use across different systems. This broad compatibility simplifies workflows and ensures that images can be accessed and viewed seamlessly across different devices and applications.',
        'Furthermore, for photographs or images with complex color gradients, JPGs lossy compression can often achieve significant file size reduction with minimal perceptible loss in quality. While PNG excels in preserving image fidelity and supporting transparency, the smaller file size of JPG images makes them more practical for scenarios where absolute image quality is not the primary concern. Overall, converting PNG to JPG offers benefits such as smaller file sizes, broad compatibility, and efficient storage and sharing, making it a preferred choice for many practical applications.'

    ]},
    {useCase: 'HEIC to JPG', summary1: [
        'Converting HEIC (High-Efficiency Image Format) to JPG (Joint Photographic Experts Group) format is often necessary due to compatibility issues and practical considerations. HEIC, developed by the Moving Picture Experts Group (MPEG), offers superior compression efficiency and image quality compared to traditional formats like JPG. However, its widespread adoption is hindered by limited support across various platforms and devices. Converting HEIC to JPG ensures broader compatibility, allowing images to be easily viewed and shared across a wide range of devices, software, and online platforms without requiring specialized HEIC support.',
        'Moreover, converting HEIC to JPG can help mitigate issues related to storage and sharing. While HEICs advanced compression techniques result in smaller file sizes compared to JPG, the lack of widespread support can pose challenges when sharing images with others or accessing them on non-compatible devices. Converting HEIC images to JPG not only ensures compatibility but also simplifies workflows by eliminating the need for conversion or specialized software to view or edit the images. This convenience is particularly valuable in professional settings where efficient collaboration and sharing of images are essential.',
        'Furthermore, for archival purposes or long-term storage, converting HEIC to JPG offers peace of mind by future-proofing image accessibility. Since JPG is a widely accepted and established image format, it is less susceptible to obsolescence compared to newer formats like HEIC. By converting HEIC images to JPG, users can ensure that their images remain accessible and viewable even as technology evolves, safeguarding against potential compatibility issues or data loss. Overall, converting HEIC to JPG provides enhanced compatibility, convenience, and long-term accessibility, making it a practical choice for managing and sharing images in various contexts.'
    ] },

    {useCase: 'Webp to JPG', summary1: [
        'Converting WebP (Web Picture Format) to JPG (Joint Photographic Experts Group) is often undertaken for compatibility and convenience reasons. WebP, developed by Google, offers superior compression efficiency and smaller file sizes compared to JPG, making it ideal for web use where fast loading times and reduced bandwidth consumption are crucial. However, despite its advantages, WebP support may be limited across certain platforms, devices, and software. Converting WebP images to JPG ensures broader compatibility, allowing them to be easily viewed and shared across a wide range of systems without requiring specialized WebP support.',
        'Additionally, converting WebP to JPG can simplify workflows and enhance accessibility. While WebP excels in compression efficiency, its limited support can pose challenges when sharing images or accessing them on non-compatible devices or platforms. Converting WebP images to JPG eliminates compatibility issues, ensuring that images can be readily viewed, edited, and shared without the need for additional conversion steps or specialized software. This convenience streamlines collaboration and communication processes, particularly in environments where seamless image sharing and accessibility are essential.',
        'Furthermore, for archival and long-term storage purposes, converting WebP to JPG provides a future-proofing solution. JPG is a widely accepted and established image format with extensive support across various systems and software. By converting WebP images to JPG, users can ensure that their images remain accessible and viewable over time, mitigating the risk of format obsolescence or compatibility issues. This approach safeguards against potential data loss and ensures that images retain their visual integrity, making it a practical choice for managing and preserving images in diverse contexts. Overall, converting WebP to JPG offers enhanced compatibility, convenience, and long-term accessibility, making it a valuable option for managing and sharing images effectively.'
    ] },
    {useCase: 'JPG to HEIC', summary1: [
        'Converting JPG (Joint Photographic Experts Group) to HEIC (High-Efficiency Image Format) is increasingly relevant for users seeking improved compression efficiency and image quality. HEIC, developed by the Moving Picture Experts Group (MPEG), offers superior compression capabilities compared to JPG, resulting in smaller file sizes without compromising image quality. This makes HEIC ideal for mobile photography and storage-constrained environments where optimizing file size is crucial. Converting JPG to HEIC enables users to take advantage of these benefits, allowing them to store more images with minimal impact on device storage capacity.',
        'Moreover, converting JPG to HEIC can enhance compatibility and streamline image sharing workflows. While JPG enjoys widespread support across various platforms and devices, HEIC adoption is growing, particularly in newer operating systems and applications. By converting JPG images to HEIC, users can future-proof their image libraries and take advantage of HEICs advanced compression technology without sacrificing compatibility. This ensures that images can be easily viewed, edited, and shared across a wide range of devices and software, providing flexibility and convenience in image management and distribution.',
        'Furthermore, for users seeking to optimize storage space or reduce bandwidth consumption, converting JPG to HEIC offers significant benefits. HEICs efficient compression techniques allow for smaller file sizes compared to JPG, enabling users to store more images without compromising image quality. This is particularly advantageous for mobile users and cloud-based storage solutions where storage capacity is limited or costly. Converting JPG to HEIC allows users to maximize storage efficiency and minimize data usage while maintaining visual fidelity, making it a practical choice for managing and storing images in modern digital environments. Overall, converting JPG to HEIC provides users with enhanced compression efficiency, compatibility, and storage optimization, making it a valuable option for optimizing image management and distribution workflows.',
    ] },
    {useCase: 'JPG to Webp', summary1: [
        'Converting JPG (Joint Photographic Experts Group) to WebP offers significant advantages in terms of file size reduction and web performance optimization. WebP, developed by Google, employs advanced compression techniques that often result in smaller file sizes compared to JPG while maintaining comparable image quality. This reduction in file size is particularly beneficial for web use, where faster loading times and reduced bandwidth consumption are paramount. By converting JPG to WebP, web developers and content creators can optimize their websites and applications, improving user experience and overall performance.',
        'Moreover, converting JPG to WebP enhances compatibility across modern web browsers and platforms. While JPG enjoys broad support, WebP adoption is steadily increasing, especially among major web browsers such as Google Chrome and Mozilla Firefox. By converting JPG images to WebP, developers can take advantage of WebPs superior compression efficiency without sacrificing compatibility. This ensures that images can be seamlessly displayed across a wide range of devices and browsers, providing consistency and reliability in web content delivery.',
        'Furthermore, for users seeking to optimize web performance and reduce bandwidth costs, converting JPG to WebP offers a compelling solution. WebPs smaller file sizes translate to faster loading times and reduced data consumption, making it an ideal choice for image-heavy websites and applications. By converting JPG images to WebP, developers can significantly improve page load speeds and user engagement while minimizing resource usage. This optimization contributes to a more efficient and sustainable web ecosystem, benefiting both content providers and end-users. Overall, converting JPG to WebP provides tangible benefits in terms of web performance optimization, compatibility, and bandwidth efficiency, making it a valuable tool for enhancing online experiences and maximizing resource utilization.'
    ] },
];

function Description(props: any) {
    const filteredContent = summaryContent.filter(content => content.useCase === props.useCase);

    if (filteredContent.length === 0) {
        return <div>No content found for the specified use case.</div>;
    }

    const { summary1, summary2 } = filteredContent[0];

    return(
        <>
        <div className='summary'>
            <div>
                <h1>{props.useCase} Conversion</h1>
                {summary1.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </div>
            <div>
                {summary2 ? (<h1>Why should you convert from {props.useCase} ?</h1>) : null}
                {summary2 ? summary2.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                )): null}
            </div>
        </div>
        <div>
            <TermsPrivacyPopup/>
        </div>
        </>
    );
}

export default Description;