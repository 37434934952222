import React, { useState } from 'react';
import './TermsAndPrivacy.css';

const TermsPrivacyPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="popup-container">
      <button onClick={togglePopup}>
            © Balazs Farkas | Terms & Privacy
      </button>
      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <h2>Terms & Privacy</h2>
            <p>
              This agreement describes the conditions and rules under which this website ("our company", "we") offers you its services at filealchemy.app.
            </p>
            <h3>Terms</h3>
            <ol>
              <li><p>Use of the filealchemy.app service is at your own risk.</p></li>
              <li><p>You bear full responsibility for any data transmitted to filealchemy.app servers.</p></li>
              <li><p>You agree not to use the filealchemy.app service to upload any illegal materials.</p></li>
              <li><p>You agree not to integrate the filealchemy.app service into your own or 3rd party applications.</p></li>
              <li><p>You may use the filealchemy.app service for any purpose, personal or commercial.</p></li>
            </ol>
            <button onClick={togglePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsPrivacyPopup;
