import React from 'react';
import { useEffect } from 'react';
import './AD.css';

function AdSenseUnitH() {
    const loadAds = () => {
        try {
            if (typeof window !== "undefined") {
            ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
            }
        } catch (error: any) {
            console.log("adsense error", error.message);
        }
    };
    
    useEffect(() => {
        loadAds();
    }, []);
    return (
    <div className='ad-horizontal'>
       <ins className="adsbygoogle"
        style={{display:"block"}}
        data-ad-client="ca-pub-9544736470032796"
        data-ad-slot="8593935535"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div>
    );
}
    
export default AdSenseUnitH;