import React, { useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import './UploadComponent.css';

const shortid = require('shortid');

interface SelectedFileProps{
    id: any,
    filename: any,
    filetype: any,
    fileimage: any,
    filesize: string
};
const UploadComponent = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [selectedfile, SetSelectedFile] = useState([] as SelectedFileProps[]);
    const [Files, SetFiles] = useState([] as SelectedFileProps[]);


    const handleDownload = (event: any) => {
        event.preventDefault(); // Prevent the form submission

        const zip = new JSZip();

        // Add files to the zip
        Files.forEach(file => {
        zip.file(file.filename, file.fileimage);
        });

        // Generate the zip file asynchronously
        zip.generateAsync({ type: 'blob' }).then(blob => {
            // Save the zip file using FileSaver.js
            saveAs(blob, 'converted_files.zip');
        });
    }

    const filesizes = (bytes: any, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const InputChange = (e: any) => {
        let filesArray = e.target.files;
    
        if (filesArray) {
            // Iterate through each file
            for (let i = 0; i < filesArray.length; i++) {
                let reader = new FileReader();
                let file = filesArray[i];
                reader.onloadend = () => {
                    SetSelectedFile(selectedfile => [...selectedfile, {
                        id: shortid.generate(),
                        filename: file.name,
                        filetype: file.type,
                        fileimage: reader.result,
                        filesize: filesizes(file.size)
                    }]);    
                };
    
                if (file) {
                    reader.readAsDataURL(file);
                }
            }
        }
        SetFiles([]);

    };
    

    const FileUploadSubmit = async (e: any) => {
        setLoading(true);
        e.preventDefault();
    
        // form reset on submit 
        e.target.reset();
    
        if (selectedfile.length > 0) {
            try {
                let convertedImages: SelectedFileProps[] = [];
                // Iterate through each selected file
                for (const file of selectedfile) {
                    // Construct the request body
                    const requestBody = {
                        image: file.fileimage.split(',')[1],
                        from: props.from,
                        to: props.to
                    };
                    // Send the POST request
                    const response = await fetch('https://europe-west4-cloudconverter-417917.cloudfunctions.net/ConversionBackEnd-prod/image', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(requestBody)
                    });
    
                    if (response.ok) {
                        const responseData = await response.json();
                        const prefix = `data:image/png;base64,`;
                        const resultBase64 = prefix + responseData.image;
                        // Update SetFiles with the new file
                        convertedImages.push({
                            id: file.id,
                            filename: file.filename.replace(/\.[^.]+$/, `.${requestBody.to}`),
                            filetype: `image/${requestBody.to}`,
                            fileimage: resultBase64,
                            filesize: filesizes(file.filesize)
                        });    
                    } else {
                        console.error('Failed to process image:', response.statusText);
                    }
                }
                SetFiles([...convertedImages]);
                SetSelectedFile([]);
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            alert('Please select file');
        }
        setLoading(false);
    };    
   
    return(
        
        <div className="fileupload-view">
                <div className="row justify-content-center  m-0">
                    <div className="col-md-6">
                        <div className="card mt-5">
                            <div className="card-body">
                                <div className="kb-data-box">
                                    <div className="kb-modal-data-title">
                                        <div className="kb-data-title">
                                            <h2 className='sub-title'>Upload image(s) you want to convert</h2>
                                            <h6 className='sub-title-desc'>We DO NOT store any images sent to our server</h6>
                                        </div>
                                    </div>
                                    <form onSubmit={FileUploadSubmit}>
                                        <div className="kb-file-upload">
                                            {
                                                <div className="file-upload-box">
                                                    <input multiple type="file" accept={"."+props.from} id="fileupload" className="file-upload-input" onChange={InputChange} />
                                                    <span>Drag and drop or <span className="file-link">Choose your file</span></span>
                                                </div>
                                            }
                                        </div>
                                        <div className="kb-attach-box-upload">
                                        {selectedfile.length > 0 ?
                                            selectedfile.map(file => (
                                            <div className="file-atc-box">
                                                {
                                                    <div className="file-image"> 
                                                        <div className="filename">{file.filename}</div>
                                                        <img src={file.fileimage} alt="" />
                                                    </div>
                                                }
                                            </div>
                                            )) : ''}
                                        </div>
                                        <div className="kb-buttons-box">
                                            {Files.length === 0 ?
                                                <button disabled={loading} type="submit" className={`btn btn-primary form-submit ${loading ? 'loading' : ''}`}>
                                                <span className="button__text">Convert</span>
                                                </button>
                                                :
                                                <div>
                                                    <button disabled={loading} type="submit" className={`btn btn-primary form-submit ${loading ? 'loading' : ''}`}>
                                                    <span className="button__text">Convert</span>
                                                    </button>

                                                    <button onClick={handleDownload} type="submit" className="btn btn-primary-download form-submit">
                                                    <span className="button__text">Download All</span>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </form>
                                    {Files.length > 0 ?
                                        <div>
                                            <hr />
                                            <div className="kb-attach-box-upload">
                                                {
                                                    Files.map((data, index) => {
                                                        const { id, filename, filetype, fileimage, filesize } = data;
                                                        return (
                                                            <div className="file-atc-box" key={index}>
                                                                {
                                                                    <div className="file-image"> 
                                                                        <div className="filename">{filename}</div>
                                                                        <img src={fileimage} alt="" />
                                                                        <a href={fileimage}  className="file-action-btn" download={filename}>Download</a>
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
    );
}

export default UploadComponent;